import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import { ReactComponent as AccessTimeIcon } from "../../assets/Timer.svg";
import { ReactComponent as SchoolIcon } from "../../assets/Training.svg";
import { ReactComponent as MoneyIcon } from "../../assets/Money.svg";

const ProgramOverview = () => {
  return (
    <Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Typography sx={{fontSize: { xs: "26px", sm: "30px" }}} gutterBottom>
          <Box
            component="span"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: { xs: "40px", sm: "50px" },
              height: { xs: "40px", sm: "50px" },
              borderRadius: "50%",
              backgroundColor: "#F6F6F6",
              color: "black",
              marginRight: { xs: "16px", sm: "36px" },
              fontSize: { xs: "20px", sm: "24px" },
            }}
          >
            1
          </Box>
          <strong>Program Overview</strong>
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f4f4f4",
            padding: { xs: "15px", sm: "20px" }, // Responsive padding
            borderRadius: "5px",
            ml: { xs: "0", sm: "86px" }, // Responsive margin-left
            mt: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: "600" }}
            gutterBottom
          >
            Level Up your Career in Operations Research
          </Typography>
          <Typography
            sx={{ fontSize: { xs: "18px", sm: "20px" }, fontWeight: "normal" }}
          >
            At DecisionOpt OR School, we are committed to helping you advance as an Operations Research Expert. 
            Our 12-week, full-time program offers a hands-on approach, tackling real-world challenges while 
            being guided by industry experts through live classes. With a focus on practical experience and 
            skill-building, our program ensures you are well-equipped to thrive in the field of Operations 
            Research. Invest in your future today and take the first step towards becoming a sought-after expert 
            in the industry.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ml: { xs: "16px", md: "86px" } }}>
        {" "}
        {/* Adjust margin for mobile */}
        <Typography
          sx={{
            fontSize: { xs: "22px", md: "24px" },
            mb: 3,
            fontWeight: "bold",
          }} // Responsive font size
          gutterBottom
        >
          Key Features
        </Typography>
        <Grid container spacing={4}>
          {" "}
          {/* Reduced spacing for mobile */}
          {[
            {
              Icon: AccessTimeIcon,
              title: "12 Weeks Full-Time",
              description:
                "Interactive live classes combined with real-world project work.",
            },
            {
              Icon: SchoolIcon,
              title: "Hands-On Training",
              description:
                "Gain practical experience with industry-level challenges.",
            },
            {
              Icon: MoneyIcon,
              title: "Flexible Payment Plan",
              description:
                "Pay in three easy installments as you progress through the program.",
            },
          ].map(({ Icon, title, description }) => (
            <Grid item xs={12} sm={6} md={4} key={title}>
              {" "}
              {/* Adjust grid item sizes */}
              <Card
                elevation={0}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }}
                >
                  <Box>
                    <Icon fontSize="large" sx={{ color: "#3f51b5" }} />
                    <Typography
                      sx={{
                        marginTop: "10px",
                        fontSize: { xs: "16px", md: "20px" }, // Responsive font size
                        fontWeight: "bold",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "18px" }, // Responsive font size
                      mt: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProgramOverview;
