import React, { useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";  // Import useNavigate hook for page redirection
import ContactModal from "./ContactModal";

const GetStarted = ({
  title,
  content,  // Content now accepts JSX/ReactNode
  buttonText,
  googleForm,
  backgroundColor,
  textColor,
  topMargin,
  useGoogleForm = false,
  bottomMargin = 0,
  buttonLink,  // New prop for internal page link
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const navigate = useNavigate();  // Initialize navigate for routing

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const handleButtonClick = () => {
    if (useGoogleForm) {
      window.open(googleForm, "_blank");
    } else if (buttonLink) {
      navigate(buttonLink);  // Use navigate to redirect internally
    } else {
      handleOpenContactModal();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        color: "white",
        borderRadius: "16px",
        px: { xs: 2, sm: 8, md: 16 },
        py: { xs: 8, sm: 8, md: 8 },
        textAlign: "center",
        maxWidth: { xs: "100%", sm: "500px" },
        width: "90%",
        margin: "auto",
        mt: { xs: 12, sm: 12, md: topMargin ?? 12 },
        mb: { xs: 12, sm: 12, md: bottomMargin ?? 12 },
      }}
    >
      <Typography sx={{ fontSize: { xs: 24, sm: 30, md: 36 }, color: textColor }} gutterBottom>
        {title}
      </Typography>
      <Box sx={{ fontSize: { xs: 14, sm: 16 }, mb: 3, color: textColor }}>
        {/* Render content as JSX */}
        {content}
      </Box>
      <Button
        variant="contained"
        onClick={handleButtonClick}  // On button click, call handleButtonClick
        sx={{
          backgroundColor: "black",
          borderRadius: 3,
          color: "white",
          textTransform: "none",
          fontSize: { xs: 16, sm: 18 },
          py: 1,
          px: 2,
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
      >
        {buttonText}
      </Button>
      <ContactModal
        open={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
    </Box>
  );
};

export default GetStarted;
