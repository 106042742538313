import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Button, Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import CorporateTraining from "../assets/Corporate.png";
import ORTalentSolution from "../assets/OR_Talent.png";
import ConsultingStrategy from "../assets/Consulting.png";
import OR_School_Photo from "../assets/OR_School_Photo.png"

const WorkDescription = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [selectedService, setSelectedService] = useState("Consulting & Services");
  const [initialized, setInitialized] = useState(false);
  const selectedServiceImageRef = useRef(null);

  const serviceData = {
    "Consulting & Services": {
      image: ConsultingStrategy,
      description:
        "Get expert advice to optimize your business processes and strategy.",
      link: "/consulting-services",
    },
    "Corporate Training": {
      image: CorporateTraining,
      description:
        "Equip your engineers with expertise to maintain and support decision optimization tools.",
      link: "/corporate-training",
    },
    "OR Talent Solutions": {
      image: ORTalentSolution,
      description: "Find and develop top talent for your organization's needs.",
      link: "/or-talent-sourcing",
    },
    "OR School": {
       image: OR_School_Photo,
       description:
         "India's first OR School, where your dreams take flight!",
       link: "/orschool-freshers",
    },
  };

  const handleServiceClick = (serviceName) => {
    setSelectedService(serviceName);
  };

  const handleUserInteraction = () => {
    setInitialized(true);
  };

  // Effects for Stopping Scroll
  // useEffect(() => {
  //   if (selectedServiceImageRef.current && initialized) {
  //     selectedServiceImageRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [selectedService, initialized]);

  return (
    <Box
      id="work-description"
      sx={{
        px: { xs: 2, md: 19 },
        backgroundColor: "#e6e6e6",
        color: "#000000",
        padding: "50px",
        position: "relative",
      }}
      onMouseMove={handleUserInteraction}
      onTouchStart={handleUserInteraction}
    >
      {!isMobile && (
        <>
          {/* <Box
            component="img"
            src={CurvedLines}
            sx={{ position: "absolute", top: 98, left: 0, width: "100%" }}
          /> */}
        </>
      )}

      <Grid
        sx={{
          mb: { xs: 6, md: 12 },
        }}
        container
        spacing={6}
      >
        <Grid item xs={12}>
          <Typography sx={{ fontSize: { xs: 42, md: 52 }, fontWeight: 600 }} >
            What we do ?
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          container
          direction="column"
          justifyContent="flex-start"
        >
          {Object.keys(serviceData).map((serviceName) => (
            <Button
              key={serviceName}
              onClick={() => handleServiceClick(serviceName)}
              sx={{
                width: "fit-content",
                fontSize: { xs: 28, md: 30 },
                mb: { xs: 3, md: 4 },
                color: "#000000",
                textTransform: "none",
                justifyContent: "flex-start",
                padding: 0,
                opacity: selectedService === serviceName ? 1 : 0.7,
                fontWeight: selectedService === serviceName ? "bold" : "normal",
              }}
            >
              {serviceName}
            </Button>
          ))}
        </Grid>

        <Grid
          sx={{ pr: { xs: 0, md: 12 } }}
          item
          xs={12}
          sm={6}
          container
          direction="column"
          justifyContent="center" // Center vertically
          alignItems="center" // Center horizontally
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center horizontally
              justifyContent: "center", // Center vertically
              textAlign: "center", // Center text
            }}
          >
            <Box
              component="img"
              ref={selectedServiceImageRef}
              src={serviceData[selectedService].image}
              alt={selectedService}
              sx={{
                marginRight: { xs: "32px", md: "64px" },
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: "100%",
                maxWidth: "400px",
                maxHeight: "200px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: 22, md: 32 },
                fontWeight: "normal",
              }}
            >
              {serviceData[selectedService].description}
            </Typography>
            <Button
              component={Link}
              to={serviceData[selectedService].link}
              variant="outlined"
              sx={{
                backgroundColor: "rgb(13, 47, 210)",
                color: "#FFFFFF",
                borderColor: "#1976d2",
                marginTop: "24px",
                padding: "10px 20px",
                textTransform: "none",
                borderRadius: "25px",
                fontSize: 16,
                "&:hover": {
                  backgroundColor: "rgb(13, 47, 210)",
                  borderColor: "#FFFFFF",
                  color: "#FFFFFF",
                },
                maxWidth: "200px",
              }}
            >
              Find out more →
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkDescription;
