import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HiringUpskillingPic1 from "../assets/HiringUpskillingPic1.png";
import GetStarted from "./GetStarted";

const ServiceList = ({ services }) => (
  <List>
    {services.map((service, index) => (
      <ListItem key={index} alignItems="flex-start">
        <ListItemText
          primary={
            <Typography sx={{ mt: 3, fontSize: { xs: 18, md: 20 } }} component="span" fontWeight="bold">
              {service.title}
            </Typography>
          }
          secondary={
            <Typography sx={{ fontSize: { xs: 16, md: 18 } }}>{service.description}</Typography>
          }
        />
      </ListItem>
    ))}
  </List>
);

const Hiring = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const corporateServices = [
    {
      title: "Talent Sourcing:",
      description:
        "We identify and screen OR professionals to match your specific needs, ensuring you have access to the best talent available for full-time or contract roles.",
    },
    {
      title: "Market Insights:",
      description:
        "For companies planning to establish or expand their OR teams in India, we provide valuable insights into the job market, including talent availability and compensation benchmarking.",
    },
    {
      title: "Tailored Hiring Solutions:",
      description:
        "Whether you need a single OR expert or a complete team, our services are flexible and tailored to meet your organization's unique requirements.",
    },
  ];

  return (
    <Container maxWidth="md">
      <Box my={isMobile ? 2 : 4}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          component="h1"
          gutterBottom
        >
          OR Talent Sourcing
        </Typography>

        <Card sx={{ mb: isMobile ? 2 : 4, boxShadow: "none" }}>
          <CardMedia
            component="img"
            height={isMobile ? "300" : "500"}
            image={HiringUpskillingPic1}
            alt="Training session"
            sx={{ border: "none" }} // Ensures no border on the image itself
          />
        </Card>
        <Typography sx={{ fontSize: isMobile ? 28 : 36, fontWeight: 600}} gutterBottom>
          For Corporates : Your{" "}
          <Typography
            sx={{ fontSize: isMobile ? 24 : 36, fontWeight: 600 }}
            component="span"
            color="#143BE2"
          >
            One-Stop Solution
          </Typography>{" "}
          for Hiring OR Talent
        </Typography>

        <Typography sx={{ mt: 3, fontSize: { xs: 22, md: 24 } }} paragraph>
          Finding the right Operations Research talent can be challenging. At
          DecisionOpt, we make it easy for you to hire skilled OR professionals
          who can drive value and innovation in your organization.
        </Typography>

        <Typography sx={{ mt: 3, fontSize: { xs: 22, md: 24 } }} gutterBottom>
          Our Services for Corporates:
        </Typography>

        <ServiceList services={corporateServices} />
      </Box>
      <GetStarted
        topMargin="8"
        bottomMargin="2"
        textColor="black"
        title="Your One-Stop Solution for Hiring OR Talent"
        content=""
        backgroundColor="#e6e6e6"
        buttonText="Get Started"
        // googleForm="https://docs.google.com/forms/d/e/1FAIpQLSe6JSrQLrYSKOdi2giZA4zjxEaFxM05GOQ9B-w5fHDOPeNpMA/viewform"
        useGoogleForm={false}
      />
    </Container>
  );
};

export default Hiring;
