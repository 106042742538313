import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import gearImage from "../assets/gear.png";
import verifiedImage from "../assets/verified.png";
import clockImage from "../assets/clock.png";

const WhyDecisionOpt = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id="why-decision-opt"
      sx={{
        mt: { xs: 8, md: 20 },
        px: { xs: 2, md: 19 },
        mb: { xs: 8, md: 24 },
      }}
    >
      <Typography
        sx={{ fontSize: { xs: 28, md: 36 }, fontWeight: 600 }}
        gutterBottom
      >
        Why DecisionOpt?
      </Typography>
      <Typography sx={{ mt: 3, fontSize: { xs: 22, md: 24 } }}>
        At DecisionOpt, we understand that optimization is an ongoing journey, not a one-time task. In today’s fast-paced and competitive business environment, staying ahead requires constant adaptation. Our team provides cutting-edge decision support tools, continuous improvements, and expert guidance to help your business thrive. With our dynamic approach, we enable you to refine operations, identify areas for improvement, and quickly adapt to changing market demands, ensuring your strategies evolve alongside business challenges.
      </Typography>
      <Typography sx={{ mt: 3, fontSize: { xs: 22, md: 24 } }}>
        What sets us apart is our personalized approach, tailoring flexible decision support systems that evolve with your unique business needs and goals.
      </Typography>
      <Box sx={{ position: "relative", mt: 5, maxWidth: "100%" }}>
        <Divider
          sx={{
            display: { xs: "none", md: "block" },
            position: "absolute",
            top: "50%",
            left: "-50vw", // Position it off-screen to the left
            width: "200vw", // Make it twice the viewport width
            zIndex: 0,
            borderBottomWidth: 2, // Increase thickness
            borderColor: "black", // Change color to black
          }}
        />
        <Grid container spacing={3}>
          {[
            [
              "Personalized and Scalable Solutions",
              gearImage,
            ],
            [
              "Trusted Services and Products",
              verifiedImage,
            ],
            [
              "Fast and Seamless end-to-end Development",
              clockImage,
            ],
          ].map(([title, imageUrl]) => (
            <Grid item xs={12} md={4} key={title}>
              <Card
                sx={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  backgroundColor: "#F2F2F2",
                  minHeight: { xs: "auto", md: 206 },
                  mb: { xs: 2, md: 0 },
                  mx: { xs: 0, md: 1.5 },
                  position: "relative",
                  mt:4,
                  zIndex: 1,
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 3  }}>
                    <img
                      src={imageUrl}
                      alt={title}
                      style={{ width: "100%", maxWidth: 80, height: "auto", objectFit: "cover" }}
                    />
                  </Box>
                  <Typography
                    sx={{ textAlign: "center", fontWeight: 700, fontSize: { xs: 16, md: 20 } }}
                    gutterBottom
                  >
                    {title}
                  </Typography>
                  
                  
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default WhyDecisionOpt;
