import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import ConsultingStrategyPic from '../assets/ConsultingServices.png';
import GetStarted from "./GetStarted";

const StyledImage = styled('img')({
  marginTop: '0rem',
  width: '100%',
  borderRadius: '8px',
  marginBottom: '2rem',
});

const SectionBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const ConsultingServices = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
        Consulting & Services
      </Typography>

      <StyledImage src={ConsultingStrategyPic} alt="Consulting & Strategy" />

      <Typography sx={{ fontSize: { xs: 20, md: 22 }, mb: 4 }}>
        At <strong> DecisionOpt </strong>, we specialize in providing <strong>Operations Research</strong> solutions that 
        help businesses optimize their operations and drive data-driven decision-making. Our team of experts are committed 
        to solving complex challenges across industries including Logistics, Oil & Gas, 
        FMCG, and more.
      </Typography>

      <Typography sx={{ fontSize: { xs: 20, md: 22 }, mb: 4 }}>
        We understand that each industry faces unique challenges. That's why we design customized solutions that are both 
        <strong> Strategic</strong> and <strong>Scalable</strong>, enabling organizations to stay competitive in today’s fast-paced business environment. 
        Whether it’s improving Supply Chain Efficiency, Optimizing Workforce Productivity, or Managing Operational Risks, 
        DecisionOpt has the expertise to help you achieve your business goals.
      </Typography>

      <Typography sx={{ fontSize: { xs: 28, md: 30 }, mb: 1 }}>
        <strong>Our Expertise</strong>
      </Typography>
      <Typography sx={{ fontSize: { xs: 20, md: 22 }, mb: 4 }}>
        We offer a wide range of consulting services tailored to meet your specific needs:
      </Typography>
      {/* Expertise List */}
      <SectionBox sx={{mb:10}}>
        <Box component="ul" sx={{ fontSize: { xs: 16, md: 18 }, pl: 5 }}>
          <li style={{ marginBottom: '1rem' }}><strong>Operations Research & Optimization:</strong> Using mathematical models, 
          algorithms, and simulations to enhance your decision-making process and optimize resource utilization.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Data Analytics & Visualization:</strong> Turning data into actionable 
          insights through advanced analytics and user-friendly dashboards, helping you make informed decisions quickly.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Supply Chain Optimization:</strong> Streamlining your supply chain 
          processes to reduce costs, improve efficiency, and increase responsiveness to market changes.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Forecasting & Demand Planning:</strong> Using predictive models to 
          forecast demand and ensure optimal inventory and resource allocation.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Risk Management & Scenario Analysis:</strong> Identifying and mitigating 
          operational risks through advanced simulation techniques and data-driven decision-making tools.</li>
        </Box>
      </SectionBox>
      {/* Industries We Serve Section */}
      <Typography sx={{ fontSize: { xs: 28, md: 30 }, mt: 5, mb: 1 }}>
        <strong>Industries We Serve</strong>
      </Typography>
      <Typography sx={{ fontSize: { xs: 20, md: 22 }, mb: 4 }}>
        Our solutions span across diverse industries, providing tailored approaches to specific operational challenges.
      </Typography>
      <SectionBox sx={{mb:10}}>
        <Box component="ul" sx={{ fontSize: { xs: 16, md: 18 }, pl: 5 }}>
          <li style={{ marginBottom: '1rem' }}><strong>Logistics & Transportation:</strong> From demand forecasting to route 
          optimization, we work with logistics providers to reduce costs, improve delivery performance, and increase overall 
          operational efficiency.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Oil & Gas:</strong> We help companies optimize their supply chains, 
          scheduling, and logistics, ensuring timely delivery while reducing operational costs and improving asset utilization.</li>
          <li style={{ marginBottom: '1rem' }}><strong>FMCG & Manufacturing:</strong> We optimize production processes, improve 
          machine scheduling, and enhance workforce management, ensuring your operations run smoothly and efficiently.</li>
        </Box>
      </SectionBox>

      <Typography sx={{ fontSize: { xs: 28, md: 30 }, mt: 5, mb: 1 }}>
        <strong>Key Projects and Case Studies</strong>
      </Typography>
      <Typography sx={{ fontSize: { xs: 20, md: 22 }, mb: 4 }}>
        We take pride in the results we deliver. Here are some of the major projects we’ve undertaken:
      </Typography>

      {/* Project Section */}
      <SectionBox sx={{mb:10}}>
        <Box component="ul" sx={{ fontSize: { xs: 16, md: 18 }, pl: 5 }}>
          <li style={{ marginBottom: '1rem' }}><strong>Demand Optimization for a London-based Logistics Company:</strong> We developed 
          advanced analytics tools, dynamic pricing strategies, and built simulation models to optimize the cost of fulfillment, resulting 
          in a 35% increase in Margin Per Quote (MPQ) during the pilot phase.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Optimal Truck Dispatcher for a Major Oil & Gas Company:</strong> We created an 
          optimization engine that considered multiple variables to efficiently manage truck dispatch, reducing transportation costs by 7% 
          and improving on-time delivery by 15%.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Tanker Dispatcher Engine for a Global Energy Corporation:</strong> A complex optimization 
          algorithm was created for oil tanker scheduling, reducing operational costs by 12% and improving fleet utilization by 20%.</li>
          <li style={{ marginBottom: '1rem' }}><strong>Machine Scheduling for an FMCG Manufacturing Company:</strong> A flexible scheduling 
          algorithm and real-time monitoring system optimized production schedules, increasing overall equipment effectiveness (OEE) by 10%.</li>
        </Box>
      </SectionBox>

      {/* <Typography sx={{ fontSize: { xs: 22, md: 24 }, mb: 4 }}>
        <strong>Why Choose DecisionOpt Consulting?</strong>
      </Typography>
      <Typography sx={{ fontSize: { xs: 18, md: 20 }, mb: 4 }}>
        <strong>Expertise:</strong> Our team of industry veterans brings real-world insights to every 
        project, ensuring that your business gets the best possible solutions tailored to its needs.
      </Typography>
      <Typography sx={{ fontSize: { xs: 18, md: 20 }, mb: 4 }}>
        <strong>Tailored Solutions:</strong> We don’t believe in one-size-fits-all solutions. Our 
        approach is highly personalized to fit the unique challenges and goals of your organization.
      </Typography>
      <Typography sx={{ fontSize: { xs: 18, md: 20 }, mb: 4 }}>
        <strong>Proven Track Record:</strong> We have successfully implemented advanced optimization 
        and analytics solutions that have delivered measurable results, enhancing operational efficiency 
        and profitability for our clients.
      </Typography>
      <Typography sx={{ fontSize: { xs: 18, md: 20 }, mb: 4 }}>
        <strong>End-to-End Support:</strong> From analysis and solution design to implementation and 
        ongoing support, we’re with you every step of the way to ensure your success.
      </Typography> */}

      <GetStarted
        title="Get Started"
        content={
          <>
            Ready to unlock the full potential of your operations? Partner with us to tackle your most pressing challenges.
          </>
        }
        buttonText="Contact Us"
        textColor="black"
        backgroundColor="#e6e6e6"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        bottomMargin={2}
      />
    </Container>
  );
};

export default ConsultingServices;
