import React from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardMedia, 
  CardContent, 
  List, 
  ListItem, 
  ListItemText,
  Container,
  Divider
} from '@mui/material';
import CorporateTrainingPic from '../assets/CorporateTrainingPic.png';
import GetStarted from "./GetStarted";

const TrainingProgram = ({ title, highlight, overview, learningPoints, outcome }) => (
  <Box mb={4}>
    <Box gap={0.6} display="flex">
    <Typography sx={{fontSize: { xs: 26, md: 28 }}} gutterBottom>
      {title}
    </Typography>
    <Typography sx={{fontSize: { xs: 26, md: 28 }}} color="#143BE2" gutterBottom>
      {highlight}
    </Typography>
    </Box>
    <Typography sx={{mt:2, fontSize: { xs: 20, md: 22 }}} >
      <strong>Program Overview:</strong> 
    </Typography>
    <Typography sx={{mt:1, mb:4, fontSize: { xs: 18, md: 20 }}}>
      {overview}
    </Typography>
    <Typography sx={{fontSize: { xs: 20, md: 22 }}} gutterBottom>
      <strong>What You'll Learn:</strong>
    </Typography>
    <List dense>
      {learningPoints.map((point, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={<Typography sx={{ fontSize: { xs: 16, md: 18 } }}>{`• ${point}`}</Typography>}
          />
        </ListItem>
      ))}
    </List>

    <Typography sx={{mb:1, fontSize: { xs: 22, md: 24 }}} paragraph>
      <strong>Program Outcome:</strong> 
    </Typography>
    <Typography sx={{ fontSize: { xs: 18, md: 20 }}}>{outcome}</Typography>
  </Box>
);

const CorporateTrainingPage = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" gutterBottom>
          Corporate Training
        </Typography>
        
        <Card elevation={0} >
          <CardMedia
            component="img"
            height="500"
            image={CorporateTrainingPic}
            alt="Corporate training session"
          />
          <CardContent>
            <Typography sx={{fontSize: { xs: 22, md: 24 }}} variant="body1" >
              At DecisionOpt, we offer specialized training programs tailored to meet the needs of corporate clients. Our programs are designed to equip your team with practical skills and knowledge in Operations Research (OR), enabling them to tackle complex decision-making challenges and drive efficiency in your organization.
            </Typography>
            
            <Typography sx={{mt:2,fontSize: { xs: 28, md: 36 }, fontWeight: 600}} variant="h6" gutterBottom>
              Why Choose DecisionOpt for Your Training Needs?
            </Typography>
            
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: { xs: 16, md: 18 } }}>
                      • Industry Expertise: Our trainers are seasoned OR professionals with extensive experience across various industries, including e-commerce, oil & gas, manufacturing, and supply chain.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: { xs: 16, md: 18 } }}>
                      • Practical Focus: We emphasize hands-on learning, ensuring participants can apply what they learn directly to their work.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: { xs: 16, md: 18 } }}>
                      • Customized Solutions: Training programs can be tailored to fit the specific needs and challenges of your organization.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        
        <Divider sx={{ mb: 4 }} />
        
        <TrainingProgram
          title={<Typography sx={{fontSize: { xs: 26, md: 28 }}}>1. Hands-on OR for <span style={{ color: '#143BE2' }}>Software Developers</span></Typography>}
          overview="This program is designed for software developers looking to enhance their understanding of Operations Research. Participants will gain foundational knowledge of OR concepts and engage in practical, hands-on projects that mimic real-world scenarios."
          learningPoints={[
            "Introduction to the basics of Operations Research",
            "Hands-on experience with OR projects and tools",
            "Techniques for debugging OR code effectively",
            "How to perform scenario analysis for decision support",
            "Skills to maintain and support decision support tools"
          ]}
          outcome="By the end of this program, participants will be equipped to contribute to OR projects, effectively support decision support tools, and apply their skills to improve operational processes within your organization."
        />
        
        <TrainingProgram
          title={<Typography sx={{fontSize: { xs: 26, md: 28 }}}>2. Advanced OR for<span style={{ color: '#143BE2' }}>Junior OR Scientists</span></Typography>}
          overview="This advanced training program is tailored for junior OR engineers and scientists who are eager to deepen their expertise in Operations Research. The focus is on developing advanced skills in modeling, performance improvement, and problem-solving."
          learningPoints={[
            "Advanced techniques for efficient problem modeling",
            "How to choose the right OR approach for different scenarios",
            "Strategies for model performance improvement",
            "Best practices in OR coding",
            "Efficient debugging of complex model issues"
          ]}
          outcome="Upon completing this program, participants will possess the advanced skills needed to design and optimize complex models, improve the performance of OR solutions, and tackle challenging decision-making problems with confidence."
        />
      </Box>
      <GetStarted
        title="Get Started"
        content={
          <>
            Partner with us to unlock your business's full potential. Our
            experts are ready to help you achieve your goals.
          </>
        }
        buttonText="Contact Us"
        textColor="black"
        backgroundColor="#e6e6e6"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        bottomMargin={2}
      />
    </Container>
  );
};

export default CorporateTrainingPage;