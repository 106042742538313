import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ContactModal from "./ContactModal";
import MenuIcon from "@mui/icons-material/Menu";
import DecisionOptLogo from "../assets/DecisionOptLogo.svg";

const Header = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);  // For the Hiring & Upskilling dropdown
  const [orschoolAnchorEl, setOrschoolAnchorEl] = useState(null);  // For the OR School dropdown
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);  // For the Services dropdown
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/";
  const backgroundColor = isHomePage ? "#F2F2F2" : "#FFFFFF";

  const handleScrollToSection = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/" && location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      // Clear the state to prevent scrolling on subsequent renders
      navigate("/", { replace: true, state: {} });
    }
  }, [location, navigate]);

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleMenuClick = (path) => {
    setAnchorEl(null); // Close the menu after click
    navigate(path);    // Navigate to the corresponding path
  };

  const handleOrschoolMenuClick = (path) => {
    setOrschoolAnchorEl(null); // Close the menu after click
    navigate(path);    // Navigate to the corresponding path (ORSCHOOL for now)
  };

  const handleServicesMenuClick = (path) => {
    setServicesAnchorEl(null); // Close the menu after click
    navigate(path);    // Navigate to the corresponding service path
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      
      <List>
        {["Consulting & Services", "Corporate Training", "OR Talent Solutions", "Our Clients", "OR School for Freshers", "OR School for Professionals", "About us"].map((text, index) => {
          const routes = {
            "Consulting & Services": "/consulting-services",
            "Corporate Training":"/corporate-training",
            "OR Talent Solutions":"/or-talent-sourcing",
            "Our Clients": "/our-clients",
            "OR School for Freshers": "/orschool-freshers",
            "OR School for Professionals":"/orschool-experienced",
            "About us": "/about-us"
          };

          return (
            <ListItem
              button
              key={text}
              onClick={() => navigate(routes[text] || "/")} // Default to "/" if no route is found
            >
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>

    </Box>
  );

  return (
    <>
      <AppBar
        position="sticky" 
        sx={{
          backgroundColor: backgroundColor,
          borderBottom: "1px solid #DBDBDB",
          color: "#000000",
          zIndex: theme.zIndex.appBar, // Keep the header on top
          top: 0,
        }}
        elevation={0}
      >
        {isMobile ? (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 16px",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={DecisionOptLogo}
                alt="DecisionOpt Logo"
                sx={{
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                sx={{
                  textTransform: "none",
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "6px",
                  padding: "4px 12px",
                  boxShadow: "none",
                  border: "1px solid #DBDBDB",
                  whiteSpace: "nowrap",
                  marginRight: "16px",
                }}
                variant="contained"
                color="white"
                onClick={handleOpenContactModal}
              >
                Contact us
              </Button>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        ) : (
          <Toolbar
            sx={{ display: "flex", alignSelf: "center", overflowX: "auto" }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={DecisionOptLogo}
                alt="DecisionOpt Logo"
                sx={{
                  marginRight: "64px",
                  height: { xs: 30, sm: "auto" },
                  minWidth: { xs: 100, sm: "auto" },
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "12px", sm: "24px" },
                alignItems: "center",
                color: "#000000",
                flexShrink: 0,
              }}
            >
              {/* Services dropdown */}
              <Button
                variant="text"
                sx={{ textTransform: "none", whiteSpace: "nowrap", color: "#000000" }}
                onClick={(e) => setServicesAnchorEl(e.currentTarget)} // Open the Services dropdown menu
              >
                Services
              </Button>
              <Menu
                anchorEl={servicesAnchorEl}
                open={Boolean(servicesAnchorEl)}
                onClose={() => setServicesAnchorEl(null)} // Close the menu
              >
                <MenuItem onClick={() => handleServicesMenuClick("/consulting-services")}>
                  Consulting & Services
                </MenuItem>
                <MenuItem onClick={() => handleServicesMenuClick("/corporate-training")}>
                  Corporate Training
                </MenuItem>
                <MenuItem onClick={() => handleServicesMenuClick("/or-talent-sourcing")}>
                  OR Talent Solutions
                </MenuItem>
              </Menu>
              <Button
                variant="text"
                sx={{ textTransform: "none", whiteSpace: "nowrap", color: "#000000" }}
                onClick={() => navigate("/our-clients")}
              >
                Clients
              </Button>
              {/* Dropdown for OR School */}
              <Button
                variant="text"
                sx={{ textTransform: "none", whiteSpace: "nowrap", color: "#000000" }}
                onClick={(e) => setOrschoolAnchorEl(e.currentTarget)} // Open the OR School dropdown menu
              >
                OR School
              </Button>
              <Menu
                anchorEl={orschoolAnchorEl}
                open={Boolean(orschoolAnchorEl)}
                onClose={() => setOrschoolAnchorEl(null)} // Close the menu
              >
                <MenuItem onClick={() => handleOrschoolMenuClick("orschool-freshers")}>
                  Freshers
                </MenuItem>
                <MenuItem onClick={() => handleOrschoolMenuClick("/orschool-experienced")}>
                  Experienced
                </MenuItem>
              </Menu>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    color: "#000000",
                  }}
                >
                  About Us
                </Button>
              </Link>
            </Box>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: "64px",
                color: "#000000",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                padding: "4px 12px",
                boxShadow: "none",
                border: "1px solid #DBDBDB",
                whiteSpace: "nowrap",
                flexShrink: 0,
              }}
              variant="contained"
              color="white"
              onClick={() => handleOpenContactModal()}
            >
              Contact us
            </Button>
          </Toolbar>
        )}
      </AppBar>
      <ContactModal
        open={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;