import React from 'react';
import { Container, Typography, Box, Avatar, Card, CardContent, IconButton, Grid } from '@mui/material';
import AlokPatel from '../assets/AlokPatel.png';
import ArvindKumar from '../assets/ArvindKumar.png';
import LinkedInLogo from '../assets/LinkedIn.svg'; 

const AboutUs = () => {
  return (
    <Container maxWidth={false} sx={{ marginTop: '40px', maxWidth: '1200px' }}>
      {/* Introduction */}
      <Typography sx={{ fontSize: 52, mb: 2 }} gutterBottom>
        About Us
      </Typography>
      <Typography sx={{ fontSize: 26, mb: 2 }}>
        Bringing <strong>Operations Research</strong> to the Forefront of <strong>Decision-Making</strong> !
      </Typography>
      <Typography sx={{ mb: 3, fontSize: { xs: 20, md: 22 } }} gutterBottom>
        At DecisionOpt, our mission is to make Operations Research (OR) a key player in the age of AI. We believe that
        the power of decision intelligence can transform industries and drive better outcomes across the globe. Our goal
        is to deliver cutting-edge OR solutions that enable organizations to make smarter, data-driven decisions.
      </Typography>

      {/* Profile Sections */}
      <Box sx={{ marginTop: '40px' }}>
        {/* Grid Container for Profile Cards */}
        <Grid container spacing={4} justifyContent="center">
          {/* Dr. Alok Patel */}
          <Grid item xs={12} sm={6} md={5}>
            <Card sx={{ textAlign: 'center', padding: '20px' }} elevation={0}>
              <Avatar
                alt="Dr. Alok Patel"
                src={AlokPatel}
                sx={{ width: 200, height: 200, margin: '0 auto', marginBottom: '20px' }}
              />
              <CardContent>
                <Typography sx={{ fontSize: { xs: 20, md: 22 } }} gutterBottom>
                  Dr. Alok Patel  
                  <IconButton
                    href="https://www.linkedin.com/in/alok7iitb/" // Link to Dr. Alok Patel's LinkedIn
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ padding: '8px' }}
                  >
                    <img src={LinkedInLogo} alt="LinkedIn" style={{ width: 30, height: 30 }} />
                  </IconButton>
                  <Typography sx={{ fontSize: { xs: 12, md: 14 } }} gutterBottom>
                    <strong>Founder & Principal OR Scientist</strong>
                  </Typography>
                </Typography>
                
                <Typography sx={{ fontSize: { xs: 14, md: 16 } }} paragraph>
                  Dr. Alok Patel started DecisionOpt with a vision to bring the power of Operations Research to the
                  forefront of business and technology. With a strong background in OR and a passion for solving complex
                  problems, Dr. Patel has been instrumental in building a company that delivers value through innovative
                  decision intelligence solutions.
                </Typography>
                <Typography sx={{ fontSize: { xs: 14, md: 16 } }} paragraph>
                  Dr. Patel's experience spans various industries, including logistics, oil & gas, and e-commerce. His
                  expertise in optimization modeling and decision support tools has made him a sought-after consultant in
                  the field of Operations Research.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Dr. Arvind Kumar */}
          <Grid item xs={12} sm={6} md={5}>
            <Card sx={{ textAlign: 'center', padding: '20px' }} elevation={0}>
              <Avatar
                alt="Dr. Arvind Kumar"
                src={ArvindKumar}
                sx={{ width: 200, height: 200, margin: '0 auto', marginBottom: '20px' }}
              />
              <CardContent>
                <Typography sx={{ fontSize: { xs: 20, md: 22 } }} gutterBottom>
                  Dr. Arvind Kumar
                  <IconButton
                    href="https://linkedin.com/in/arviphd/" // Link to Dr. Arvind Kumar's LinkedIn
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ padding: '8px' }}
                  >
                    <img src={LinkedInLogo} alt="LinkedIn" style={{ width: 30, height: 30 }} />
                  </IconButton>
                  <Typography sx={{ fontSize: { xs: 12, md: 14 } }} gutterBottom>
                    <strong>Partner & Chief OR Scientist</strong>
                  </Typography>
                </Typography>
                
                <Typography sx={{ fontSize: { xs: 14, md: 16 } }} paragraph>
                  Dr. Arvind Kumar brings a wealth of knowledge and experience to DecisionOpt as a Partner & Chief OR Scientist. 
                  With a Ph.D. in Operations Research and extensive industry experience, Dr. Kumar has been a
                  guiding force in shaping our strategic direction.
                </Typography>
                <Typography sx={{ fontSize: { xs: 14, md: 16 } }} paragraph>
                  Dr. Kumar's expertise in OR, data analytics, and AI has been invaluable in helping DecisionOpt develop
                  innovative solutions that meet the evolving needs of our clients. His mentorship ensures that we stay at
                  the cutting edge of research and technology, providing our clients with the best possible solutions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutUs;
