import React from 'react';
import { Box, Typography, Card, CardContent, Container, List, ListItem, ListItemText, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PAPProgramPage = () => {
  return (
    <Container maxWidth="md">
      {/* Header Section */}
      <Box my={4}>
        <Typography sx={{fontSize: { xs: "28px", sm: "32px", textAlign: 'center', fontWeight:600 }}} gutterBottom>
          Pay After Placement (PAP)
        </Typography>
        <Typography sx={{mt: 3, fontSize: {  xs: "18px", sm: "20px" }}} paragraph>
          The Pay After Placement (PAP) program at DecisionOpt offers students a unique opportunity to study without upfront tuition fees. You only pay once you secure a job, allowing you to focus on your education without financial stress.
        </Typography>
      </Box>

      {/* About Pay After Placement Section */}
      <Box mb={4}>
        <Typography sx={{fontSize: { xs: "20px", sm: "22px", fontWeight:600  }}} gutterBottom>
          About the Pay After Placement (PAP) Program
        </Typography>
        <Typography sx={{mt:1, fontSize: { xs: "18px", sm: "20px" }}} paragraph>
          The PAP Agreement is a legal contract that ties education at DecisionOpt to specific outcomes. It is not an education loan—there are no interest rates, no collaterals, and no upfront fees required. You will only pay a portion of your fees once you land a job that meets the salary criteria.
        </Typography>
      </Box>

      {/* How It Works Section */}
      <Box mb={4}>
        <Typography sx={{mt: 3, fontSize: { xs: "20px", sm: "22px", fontWeight:600  }}} gutterBottom>
          How It Works?
        </Typography>
        <Typography sx={{mt: 1, fontSize: { xs: "18px", sm: "20px" }}} paragraph>
          The PAP program works in a simple and straightforward way. Here’s how it works:
        </Typography>
        <List sx={{ paddingLeft: 2, marginBottom: '0' }}> {/* List styling for reduced padding */}
          <ListItem sx={{ padding: 0, marginBottom: '4px' }}> {/* Reduced padding and margin between list items */}
            <ListItemText
              primary="1. Enroll in the program and focus on your studies."
              sx={{ marginBottom: '4px' }} // Adjusts space between list item text
            />
          </ListItem>
          <ListItem sx={{ padding: 0, marginBottom: '4px' }}>
            <ListItemText
              primary="2. Once you secure a job, you will begin to pay based on your salary range."
              sx={{ marginBottom: '4px' }}
            />
          </ListItem>
          <ListItem sx={{ padding: 0, marginBottom: '4px' }}>
            <ListItemText
              primary="3. Payments are structured to be manageable, with no upfront costs."
              sx={{ marginBottom: '4px' }}
            />
          </ListItem>
          <ListItem sx={{ padding: 0, marginBottom: '4px' }}>
            <ListItemText
              primary="4. Continue making payments as per the terms until the agreed amount is paid off."
              sx={{ marginBottom: '4px' }}
            />
          </ListItem>
        </List>
      </Box>

      {/* Course Fees Section */}
      <Box mb={4}>
        <Typography sx={{mt: 3, fontSize: { xs: "20px", sm: "22px", fontWeight:600  }}} gutterBottom>
          Course Fees
        </Typography>
        <Typography sx={{mt: 1, fontSize: { xs: "18px", sm: "20px" }}} paragraph>
          Our PAP program is designed to be affordable and accessible. The fees are determined based on your salary after placement. The more you earn, the more you contribute towards the program, but it’s structured to ensure you can manage the payments easily.
        </Typography>
      </Box>

      {/* PAP Table */}
      <Box mb={4}>
        <Typography sx={{mt: 3, mb: 2, fontSize: { xs: "20px", sm: "22px", fontWeight:600  }}} gutterBottom>
          PAP Payment Structure
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}><strong>Category</strong></TableCell>
                <TableCell sx={{ textAlign: 'center' }}><strong>Salary Range</strong></TableCell>
                <TableCell sx={{ textAlign: 'center' }}><strong>Monthly Payable Amount</strong></TableCell>
                <TableCell sx={{ textAlign: 'center' }}><strong>Tenure in Months</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Replace these rows with your provided data */}
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>A</TableCell> 
                <TableCell sx={{ textAlign: 'center' }}>{"<"} 4.99 LPA </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹ 0</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>NA</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>B</TableCell>
                <TableCell sx={{ textAlign: 'center' }}> 5 - 7.99 LPA </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹7,000*</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>36</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>C</TableCell>
                <TableCell sx={{ textAlign: 'center' }}> 8 - 9.99 LPA</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹10,000*</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>36</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>D</TableCell>
                <TableCell sx={{ textAlign: 'center' }}> 10 - 14.99 LPA</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹12,000*</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>36</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>E</TableCell>
                <TableCell sx={{ textAlign: 'center' }}> {">"} 15 LPA</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹15000*</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>36</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body1" sx={{ mt: 4, textAlign: 'center', fontSize: '0.655rem' }} paragraph>
          <span style={{ color: 'red' }}>*</span>Excluding GST
        </Typography>
      </Box>

      {/* Dropout Clause Section */}
      <Box mb={4}>
        <Typography sx={{mt: 3, fontSize: { xs: "20px", sm: "22px", fontWeight:600  }}} gutterBottom>
          Dropout Clause
        </Typography>
        <Typography sx={{mt: 1, fontSize: { xs: "18px", sm: "20px" }}} paragraph>
          If you decide to leave the program before securing a job, you will be required to pay a partial fee based on the amount of coursework completed. The amount will be calculated based on your progress and the terms outlined in the PAP agreement.
        </Typography>
      </Box>
      <Box mb={4}>
        <TableContainer sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}><strong>During Week</strong></TableCell>
                <TableCell sx={{ textAlign: 'center' }}><strong>Payment</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Row 1 */}
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>During Trial Period <br />(Week 1)</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>No Payment</TableCell>
              </TableRow>
              {/* Row 2 */}
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>Week 2 - 5</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹10,000*</TableCell>
              </TableRow>
              {/* Row 3 */}
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>Week 6 - 15</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹50,000*</TableCell>
              </TableRow>
              {/* Row 4 */}
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>Week 16-25</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>₹2,50,000*</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body1" sx={{ mt: 4, textAlign: 'center', fontSize: '0.655rem' }} paragraph>
          <span style={{ color: 'red' }}>*</span>Excluding GST
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '0.655rem' }} paragraph>
          <span style={{ color: 'red' }}>**</span>These drop-out clauses also applicable if you breach the DecisionOpt Student Code of Conduct.
        </Typography>
      </Box>
      {/* FAQ Section */}
      <Box mb={4}>
        <Typography sx={{mt: 3, fontSize: { xs: "20px", sm: "22px", fontWeight:600  }}} mb={4} gutterBottom>
          Frequently Asked Questions (FAQ)
        </Typography>
        {/* Accordion for FAQs */}
        <Accordion mt={2}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>What is the PAP Agreement and how does it work?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
            The Pay After Placement Agreement is a way to pay your Operations Research Course fee. 
            PAP at DecisionOpt is an agreement between students and DecisionOpt under which 
            you agree to pay a fixed monthly payment for 36 months. The payment starts only 
            once you earn a salary above the threshold amount. The threshold amount is the 
            minimum salary you have to earn before you start paying DecisionOpt your course 
            fee. If you do not get the promised outcome, you pay us nothing at all. Your 
            education is free.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>What is the interest rate on my Pay After Placement(PAP) payments?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              PAP Agreement is NOT a loan, so there is no interest on your payment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>When do my PAP repayments start and what if I get fired?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              It will come into action once you secure a job that pays at least the minimum threshold salary after completing the course. <br /><br />
              In the event that you lose your job due to any of the following reasons:<br />
              (i) Your employer/company goes out of business<br />
              (ii) A company-wide pay cut is introduced<br />
              (iii) You are laid off by your employer/company due to economic difficulties<br />
              Your payments will be automatically paused once you report the change and provide the necessary supporting documents.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>What happens if I don’t get an offer for the threshold amount for my course?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              The DecisionOpt team is constantly working to find you great placement opportunities. 
              In the rare event that you don’t secure a job with a salary of 5 LPA or more, you 
              won’t be required to make any payments, and your PAP payments will not begin. 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}> What happens if I don’t get a job after completing the course?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              If you don’t secure a job within one year of completing the course, you won’t need 
              to make any payments.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>Do I need to submit any document post getting a job?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              Under the Pay After Placement (PAP) Agreement, you are legally required to provide all documents related 
              to your income, such as your Offer Letter, Salary Slips, Income Tax Returns, Bank Statements, and any 
              other relevant documents. This disclosure enables us to offer our courses to you with ₹0 upfront fees.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>Can I see a copy of the PAP Agreement?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              During the admission process, a copy of the agreement will be provided to you at the end.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
            <Typography sx={{ fontSize: { xs: "18px", sm: "20px" }}}>How do I pay my PAP amount?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" }}} color="textSecondary">
              Upon clearing the assessment at the end of the trial period the student is required to complete this 
              application with the respective NBFC partner for PAP. <br />
              Once you are placed, your assigned NBFC partner will approve a one-time, zero-interest capital amount, 
              which can be repaid in monthly installments over a maximum period of 36 months. Monthly payments will 
              begin once you secure a job offer with a salary of 5 LPA or more. The amount you need to pay will 
              depend on your CTC. Please refer to the table in our PAP Fees Structure section to understand the different 
              CTC slabs.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default PAPProgramPage;
