import React from "react";
import { Box, Typography, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Redirect from "../Redirect";

const PayAfterPlacement = () => {
  return (
    <Box>
      <Typography sx={{fontSize: { xs: "26px", sm: "30px" }}} gutterBottom>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "40px", sm: "50px" },
            height: { xs: "40px", sm: "50px" },
            borderRadius: "50%",
            backgroundColor: "#F6F6F6",
            color: "black",
            marginRight: { xs: "16px", sm: "36px" },
            fontSize: { xs: "20px", sm: "24px" },
          }}
        >
          4
        </Box>
        <strong>Upfront Fees </strong>
      </Typography>
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          padding: { xs: "15px", sm: "20px" }, // Responsive padding
          borderRadius: "5px",
          ml: { xs: "0", sm: "86px" }, // Responsive margin-left
          mt: "20px",
          mb: "20px",
        }}
      >
        <Typography sx={{ mb:2 ,fontSize: { xs: "18px", sm: "20px" } }}>
          <strong>Your Future, Your Terms – Pay as You Learn!</strong>
        </Typography>
        
        <Typography sx={{ mb: 2, fontSize: { xs: "16px", sm: "18px" } }} color="textSecondary">
          We understand the importance of financial flexibility, which is why we offer a convenient 
          three-installment payment plan. This flexible payment structure allows you to spread out 
          your payments, giving you the freedom to focus on building the skills and expertise you 
          need without worrying about finances.
        </Typography>  
        
      </Box>

      <Typography
        sx={{
          marginLeft: { xs: "0", sm: "86px" }, // Responsive margin-left
          fontSize: { xs: "22px", sm: "26px" }, // Responsive font size
          fontWeight: "bold",
          mt: 5,
          mb: 2,
        }}
        variant="h5"
        gutterBottom
      >
        <strong>Payment Structure</strong>
        <Typography sx={{ mt: 2, fontSize: { xs: "16px", sm: "18px" } }} color="textSecondary">
          You can confidently invest in your future while progressing through the program at your 
          own pace. To help you manage your payments, the course fees are split into the following 
          installments:
        </Typography>
        {/* PAP Table */}
        <Box mb={4} mt={4}>
              <TableContainer sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }}><strong>Payment Term</strong></TableCell>
                      <TableCell sx={{ textAlign: 'center' }}><strong>Net Payable Amount</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }}>Starting of Course</TableCell> 
                      <TableCell sx={{ textAlign: 'center' }}> ₹ 50,000*</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }}>Week 2</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}> ₹ 25,000*</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center' }}>Week 10</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}> ₹ 25,000*</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ mt: 4, textAlign: 'center', fontSize: '0.655rem' }}><span style={{ color: 'red' }}>*</span> Excluding GST</Typography>
            </Box>
      </Typography>
    </Box>
    
  );
};

export default PayAfterPlacement;
