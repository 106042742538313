import React from "react";
import { Box, Typography, Link, IconButton, Divider, useMediaQuery, useTheme } from "@mui/material";
import LinkedIn from '../assets/LinkedIn.svg';
import Location from '../assets/Location.svg';
import Email from '../assets/Email.svg';
import GrayLogo from '../assets/GrayLogo.svg';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: isMobile ? "20px 0" : "40px 0",
        minHeight: "200px", // Ensure a minimum height for the footer
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center", // Align items to the center vertically
          width: "90%",
          maxWidth: "1200px",
          flexGrow: 1, // Allow the content to take available space
        }}
      >
        {/* DecisionOpt Logo - vertically centered */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%", // Ensures logo is centered vertically
            paddingTop: isMobile ? "20px" : "0", // Adjusts logo padding for mobile
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: isMobile ? 20 : 24,
              mt: 2,
              mb: isMobile ? 2 : 0,
            }}
          >
            <img
              src={GrayLogo}
              alt="DecisionOpt Logo"
              style={{ height: isMobile ? "40px" : "50px", marginRight: "10px" }}
            />
            DecisionOpt
          </Typography>
        </Box>

        {/* Map (only on desktop) */}
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "350px",
              margin: "0 20px",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22397279.509171404!2d79.24893101108538!3d26.19944689018234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13438b04bf03%3A0x6151b067045f99ef!2sDecisionOpt%20Consulting%20Private%20Limited!5e0!3m2!1sen!2sin!4v1733737933162!5m2!1sen!2sin"
              width="500"
              height="200"
              style={{ border: "0", borderRadius: "8px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </Box>
        )}

        {/* Contact Info (LinkedIn, Location, Email) */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "center" : "flex-start",
            marginTop: "20px",
            justifyContent: "center", // Centering vertically within the footer
            height: "100%", // Ensuring the contact info is aligned properly
          }}
        >
          <Box sx={{ display: "flex", mb: "15px" }}>
            <IconButton
              component={Link}
              href="https://www.linkedin.com/company/decisionopt/"
              target="_blank"
              rel="noopener"
              sx={{ color: "#fff", marginLeft: "15px" }}
            >
              <Box component="img" src={LinkedIn} alt="LinkedIn Logo" />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
            <Box
              component="img"
              src={Location}
              alt="Location Logo"
              sx={{ marginRight: "10px" }}
            />
            <Typography variant="body2">Bengaluru, India</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              component="img"
              src={Email}
              alt="Email Logo"
              sx={{ marginRight: "10px" }}
            />
            <Link
              href="mailto:hello@decisionopt.com"
              sx={{ color: "#fff", textDecoration: "none" }}
            >
              <Typography variant="body2">contact@decisionopt.com</Typography>
            </Link>
          </Box>
        </Box>
      </Box>

      {/* Footer Links */}
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "flex-start",
          justifyContent: "center",
          width: "100%",
          paddingTop: "20px",
        }}
      >
        <Link href="/about-us" sx={{ color: "#fff", margin: isMobile ? "10px 0" : "0 15px" }}>
          About
        </Link>
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "#fff", margin: "0 15px" }}
          />
        )}
        <Link href="/privacy-policy" sx={{ color: "#fff", margin: isMobile ? "10px 0" : "0 15px" }}>
          Privacy Policy
        </Link>
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "#fff", margin: "0 15px" }}
          />
        )}
        <Link href="/Support" sx={{ color: "#fff", margin: isMobile ? "10px 0" : "0 15px" }}>
          Support
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
