import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import ConsultingStrategyPic from '../assets/Clients.png';
import GetStarted from "./GetStarted";

const StyledImage = styled('img')({
  width: '100%',
  borderRadius: '8px',
  marginBottom: '2rem',
});

const SectionBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const OurClients = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
        Our Clients
      </Typography>

      <StyledImage src={ConsultingStrategyPic} alt="Consulting & Strategy" />

      <Typography sx={{ mt: 3, mb: 4, fontSize: { xs: 22, md: 24 } }} paragraph >
        At DecisionOpt, we take pride in delivering tailored Operations Research solutions that address the unique challenges faced by our clients. Our expertise spans various industries, including logistics, oil & gas, and FMCG, helping organizations optimize their operations and make data-driven decisions. Here are some of our key projects:
      </Typography>

      {/* Demand Optimization Section */}
      <SectionBox>
        <Typography sx={{ mb:2, fontSize: { xs: 26, md: 28 } }} gutterBottom >
          1. <strong>Demand Optimization</strong>
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }}>
          <strong>Client:</strong> London-based Logistics Company
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }} paragraph>
          <strong>Project Overview:</strong> We partnered with a leading logistics company in London to build advanced analytics solutions focused on optimizing the Cost of Fulfillment (CTF). By leveraging data and analytics, we developed pricing strategies and built simulation capabilities for what-if analysis.
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }}>
          <strong>Key Achievements:</strong>
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 0, fontSize: { xs: 14, md: 16 }  }}>
          <li>Developed robust analytics tools to optimize fulfillment costs</li>
          <li>Designed dynamic pricing strategies to maximize profitability</li>
          <li>Created simulation models for scenario planning and risk management</li>
          <li><strong>Quantitative Impact:</strong> Margin Per Quote (MPQ) increased by 35% in the pilot phase</li>
        </Box>
      </SectionBox>

      {/* Optimal Truck Dispatcher Section */}
      <SectionBox>
        <Typography sx={{ mb:2, fontSize: { xs: 26, md: 28 } }} gutterBottom >
          2.<strong> Optimal Truck Dispatcher</strong>
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }}>
          <strong>Client:</strong> Major Oil & Gas Company
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, fontSize: { xs: 16, md: 18} }} paragraph>
          <strong>Project Overview:</strong> We developed an advanced optimization engine for a leading oil & gas company to efficiently dispatch trucks for product delivery. The solution considered various constraints such as truck capacity, delivery windows, and route optimization.
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }}>
          <strong>Key Achievements:</strong>
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 0, fontSize: { xs: 14, md: 16 } }}>
          <li>Implemented a sophisticated algorithm for optimal truck routing and scheduling</li>
          <li>Integrated real-time data feeds for dynamic decision-making</li>
          <li>Developed a user-friendly interface for dispatchers to manage operations</li>
          <li><strong>Quantitative Impact:</strong> Reduced transportation costs by 7% and improved on-time delivery by 15%</li>
        </Box>
      </SectionBox>

      {/* Tanker Dispatcher Engine Section */}
      <SectionBox>
        <Typography sx={{ mb:2, fontSize: { xs: 26, md: 28 } }} gutterBottom >
          3. <strong>Tanker Dispatcher Engine</strong>
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }}>
          <strong>Client:</strong> Global Energy Corporation
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }} paragraph>
          <strong>Project Overview:</strong> We created a sophisticated tanker dispatcher engine for a global energy corporation to optimize their maritime logistics operations. The solution focused on efficient scheduling and routing of oil tankers while considering various operational constraints and market dynamics.
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 2, fontSize: { xs: 16, md: 18 }}}>
          <strong>Key Achievements:</strong>
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 0, fontSize: { xs: 14, md: 16 } }}>
          <li>Developed a complex optimization algorithm for tanker scheduling and routing</li>
          <li>Integrated real-time market data and weather information for informed decision-making</li>
          <li>Created a robust simulation environment for scenario analysis and risk assessment</li>
          <li><strong>Quantitative Impact:</strong> Reduced operational costs by 12% and improved fleet utilization by 20%</li>
        </Box>
      </SectionBox>

      {/* Machine Scheduling Section */}
      <SectionBox>
        <Typography sx={{ mb:2, fontSize: { xs: 26, md: 28 } }} gutterBottom >
          4. <strong>Machine Scheduling</strong>
        </Typography>
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }}>
          <strong>Client:</strong> FMCG Manufacturing Company
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, fontSize: { xs: 16, md: 18 } }} paragraph>
          <strong>Project Overview:</strong> We designed and implemented an advanced machine scheduling system for a leading FMCG manufacturing company. The solution optimized production schedules across multiple production lines, considering factors such as machine capabilities, maintenance requirements, and order priorities.
        </Typography>
        <Typography variant="body1" paragraph sx={{ mb: 2, fontSize: { xs: 16, md: 18 }}}>
          <strong>Key Achievements:</strong>
        </Typography>
        <Box component="ul" sx={{ pl: 4, mb: 0, fontSize: { xs: 14, md: 16 } }}>
          <li>Developed a flexible scheduling algorithm adaptable to changing production requirements</li>
          <li>Implemented a real-time monitoring system for production line performance</li>
          <li>Created a user-friendly interface for production managers to adjust schedules as needed</li>
          <li><strong>Quantitative Impact:</strong> Increased overall equipment effectiveness (OEE) by 10%</li>
        </Box>
      </SectionBox>
      <GetStarted
        title="Get Started"
        content={
          <>
            Partner with us to unlock your business's full potential. Our
            experts are ready to help you achieve your goals.
          </>
        }
        buttonText="Contact Us"
        textColor="black"
        backgroundColor="#e6e6e6"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        bottomMargin={10}
      />
    </Container>
  );
};

export default OurClients;
