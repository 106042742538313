import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import WhyDecisionOpt from "./components/WhyDecisionOpt";
import Testimonials from "./components/Testimonials";
import WorkDescription from "./components/WorkDescription";
import GetStarted from "./components/GetStarted";
import SupportRedirect from "./components/SupportRedirect"; // Import SupportRedirect
import Redirect from "./components/Redirect";
import Footer from "./components/Footer";
import { Box, useMediaQuery } from "@mui/material";
import CorporateTraining from "./components/CorporateTraining";
import ORTalentSourcing from "./components/ORTalentSourcing";
import ConsultingServices from "./components/ConsultingServices";
import Clients from "./components/Client"
import AboutUs from "./components/AboutUs";
import Support from "./components/Support";
import RightBlock from "./assets/RightBlock.svg";
import ScrollToTop from "./components/ScrollToTop";
import ORSchoolExperienced from "./components/ORSchool-experienced";
import ORSchoolFreshers from "./components/ORSchool-freshers";  // Import the new Freshers component
import PrivacyPolicy from "./components/PrivacyPolicy";
import PayAfterPlacement from "./components/ORSchool/PayAfterPlacement";
import ORSchoolSection from "./components/ORSchoolSection";
import NotFound from "./components/NotFound";

const HomePage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box position="relative">
        <HeroSection />
        {!isMobile && (
          <Box
            component="img"
            src={RightBlock}
            alt="RightBlock"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              mt: 8.5,
              mr: 15,
              maxHeight: "140%",
              display: { xs: "none", md: "block" },
            }}
          />
        )}
      </Box>
      <WhyDecisionOpt />
      <WorkDescription />
      {/* 
      <ORSchoolSection />  //Remove the comment tag to add the ORSchool Section 
      */}
       {/* 
       <Testimonials />   //Remove the comment tag to add the testimonials 
       */}
      <GetStarted
        title="Get Started"
        content={
          <>
            Partner with us to unlock your business's full potential. Our
            experts are ready to help you achieve your goals.
          </>
        }
        buttonText="Contact Us"
        textColor="black"
        backgroundColor="#F6F6F6"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        bottomMargin={13}
      />
    </>
  );
};

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <Box sx={{ overflowX: "hidden" }}>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/corporate-training" element={<CorporateTraining />} />
          <Route path="/or-talent-sourcing" element={<ORTalentSourcing />} />
          <Route path="/consulting-services" element={<ConsultingServices />} />
          <Route path="/our-clients" element={<Clients />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/orschool-experienced" element={<ORSchoolExperienced />} />
          <Route path="/orschool-freshers" element={<ORSchoolFreshers />} />  
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pay-after-placement" element={<PayAfterPlacement />} />
          <Route path="/Support" element={<Support />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  </Router>
);

export default App;
