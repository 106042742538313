import React from "react";
import { Box, Typography, Avatar, Grid } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlokPatel from "../../assets/AlokPatel.png";
import ArvindKumar from "../../assets/ArvindKumar.png";
import SalmanMemon from "../../assets/SalmanMemon.png";
import AbhikGiri from "../../assets/AbhikGiri.png";
import RajshekharSinghania from "../../assets/Rajshekhar.png";
import VijayGupta from "../../assets/VijayGupta.png";

const instructors = [
  {
    name: "Dr. Alok Patel",
    image: AlokPatel,
    linkedin: "https://www.linkedin.com/in/alok7iitb/",
  },
  {
    name: "Dr. Arvind Kumar",
    image: ArvindKumar,
    linkedin: "https://linkedin.com/in/arviphd/",
  },
  {
    name: "Dr. Vijay Gupta",
    image: VijayGupta,
    linkedin: "https://www.linkedin.com/in/vijaygup/",
  },
  {
    name: "Abhik Giri",
    image: AbhikGiri,
    linkedin: "https://www.linkedin.com/in/abhikgiri/",
  },
  {
    name: "Rajshekhar Singhania",
    image: RajshekharSinghania,
    linkedin: "https://www.linkedin.com/in/rajshekharsinghania/",
  },
  {
    name: "Salman Memon",
    image: SalmanMemon,
    linkedin: "https://www.linkedin.com/in/salman-memon-6ab76964/",
  },
  
];

const MeetYourInstructors = () => {
  return (
    <Box>
      <Typography sx={{fontSize: { xs: "26px", sm: "30px" }}} gutterBottom>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "40px", sm: "50px" },
            height: { xs: "40px", sm: "50px" },
            borderRadius: "50%",
            backgroundColor: "#F6F6F6",
            color: "black",
            marginRight: { xs: "16px", sm: "36px" },
            fontSize: { xs: "20px", sm: "24px" },
            mb: { xs: 2, sm: 5 },
          }}
        >
          5
        </Box>
        <strong>Meet Your Instructors</strong>
      </Typography>
      <Grid
        sx={{ mx: { xs: -2, sm: 2 }, justifyContent: "center" }}
        container
        spacing={2}
      >
        {instructors.map((instructor, index) => (
          <Grid
            item
            xs={12} // Full width on mobile
            sm={6} // Half width on small screens
            md={4} // One-third width on medium screens
            key={index}
            sx={{ textAlign: "center" }}
          >
            <Avatar
              alt={instructor.name}
              src={instructor.image}
              sx={{ width: 200, height: 200, margin: "auto" }}
            />
            <Typography
              sx={{ fontSize: "20px", fontWeight: "500", mt: 2 }}
              gutterBottom
            >
              {instructor.name}
            </Typography>
            <a
              href={instructor.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon fontSize="small" sx={{ color: "#000000" }} />
            </a>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          padding: "15px", // Reduced padding for mobile
          marginTop: "20px", // Reduced margin for mobile
          borderRadius: "5px",
          ml: { xs: 0, sm: "20px" }, // Adjust margin for mobile
          mr: { xs: 0, sm: "20px" }, // Adjust margin for mobile
        }}
      >
        <Typography fontWeight="bold" sx={{ fontSize: "26px", mb: 2 }}>
          Learn from Leading OR Experts
        </Typography>
        <Typography sx={{ fontSize: "18px" }}>
          With over <strong>70+</strong> years of collective experience in Operations Research, Data Science, 
          and Decision Sciences, our team is committed to delivering high-quality, insightful 
          content. Our industry veteran instructors bring real-world experience into the 
          classroom, mentoring you throughout the program. We aim to equip you with valuable 
          insights and practical skills to solve real-world problems, preparing you for a 
          successful career in Operations Research.
          {/* Our instructors are industry veterans who bring real-world insights
          into the classroom. They will mentor you throughout the program and
          help you gain the skills necessary for a successful career in
          Operations Research. */}
        </Typography>
      </Box>
    </Box>
  );
};

export default MeetYourInstructors;
