import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // To navigate back to home
import GetStarted from "./GetStarted";

const NotFoundPage = () => {
  return (
    <Container maxWidth="md">
      <Box my={4} textAlign="center">
        <Typography  sx={{fontSize:{ xs: 58, md: 62 }, mt:4, mb:4}} gutterBottom>
          Oops! Page Not Found
        </Typography>
        <Typography sx={{fontSize:{ xs: 24, md: 26 }, mt:10, mb:4, color: '#666'}} gutterBottom>
          The page you're looking for doesn't exist.
        </Typography>
        
        {/* Optional message */}
        <Typography sx={{fontSize:{ xs: 24, md: 26 }, mt:4 }} paragraph>
          It seems that the link you followed may be broken, or the page may have been moved.
        </Typography>
        
        {/* <Button 
          variant="contained" 
          color="primary" 
          component={Link} 
          to="/" 
          sx={{ mt: 3 }}
        >
          Go to Homepage
        </Button> */}
      </Box>
      <Container maxWidth="md">
      <GetStarted
        title="Need Assistance?"
        content={<>Get the support you need to grow your business, Talk to our Team.</>}
        buttonText="Contact Us"
        textColor="black"
        backgroundColor="#e6e6e6"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        bottomMargin={15}
      />
      </Container>
    </Container>
  );
};

export default NotFoundPage;
