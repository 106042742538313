import React from 'react';
import { Container} from '@mui/material';
import GetStarted from "./GetStarted";

const Support = () => {
  return (
    <Container maxWidth="md">
      <GetStarted
        title="Need Assistance?"
        content={
          <>
            Get the support you need to grow your business. Our team is here to help you succeed.
          </>
        }
        buttonText="Contact Us"
        textColor="black"
        backgroundColor="#e6e6e6"
        googleForm="https://forms.gle/LLFj8MSK8JkCrMc39"
        bottomMargin={15}
      />
    </Container>
  );
};

export default Support;