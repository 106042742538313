import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";

const IndustrialReadyCurriculum = () => {
  return (
    <Box sx={{ padding: { xs: "16px", sm: "24px" } }}>
      <Typography sx={{fontSize: { xs: "26px", sm: "30px" }}} gutterBottom>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "40px", sm: "50px" },
            height: { xs: "40px", sm: "50px" },
            borderRadius: "50%",
            backgroundColor: "#F6F6F6",
            color: "black",
            marginRight: { xs: "16px", sm: "36px" },
            fontSize: { xs: "20px", sm: "24px" },
          }}
        >
          3
        </Box>
        <strong>Industrial Ready Curriculum</strong>
      </Typography>
      <Box
        sx={{
          border: "1px solid #969696",
          borderRadius: "12px",
          padding: { xs: "16px", sm: "24px" },
          ml: { xs: "0", sm: "86px" },
          mt: "36px",
        }}
      >
        <Card
          elevation={0}
          sx={{ backgroundColor: "#f4f4f4", borderRadius: "5px" }}
        >
          <CardContent
            sx={{
              backgroundColor: "#f4f4f4",
              borderRadius: "5px",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "18px", sm: "20px" }, fontWeight: "bold" }}
              gutterBottom
            >
              Learn What Matters in the Real World
            </Typography>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
              Our curriculum is tailored to prepare you for the challenges faced
              by Operations Research professionals in the industry. The program
              focuses on the following:
            </Typography>
          </CardContent>
        </Card>
        <ul style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr', 
          gap: '10px', 
          fontSize: { xs: "16px", sm: "18px" }
        }}>
          <li>OR Theory (LP, MIP, NLP)</li>
          <li>Advanced OR modeling techniques.</li>
          <li>Optimization algorithms and heuristics for real-world problem-solving.</li>
          <li>Efficient Python Programming and Best Coding Practices.</li>
          <li>Dev Tools for OR: How to test, manage, and debug OR codes and platforms?</li>
          <li>Introduction to Simulation Methodology and ML techniques.</li>
          <li>Best Practices to Make OR work in Industry.</li>
          <li>Working with industry datasets and solving case studies.</li>
          <li>Interview preparation and soft skills training for the job market.</li>
        </ul>
      </Box>
    </Box>
  );
};

export default IndustrialReadyCurriculum;
