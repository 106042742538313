import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import Redirect from "../Redirect";

const PayAfterPlacement = () => {
  return (
    <Box>
      <Typography sx={{fontSize: { xs: "26px", sm: "30px" }}} gutterBottom>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "40px", sm: "50px" },
            height: { xs: "40px", sm: "50px" },
            borderRadius: "50%",
            backgroundColor: "#F6F6F6",
            color: "black",
            marginRight: { xs: "16px", sm: "36px" },
            fontSize: { xs: "20px", sm: "24px" },
          }}
        >
          4
        </Box>
        <strong>Pay After Placement</strong>
      </Typography>
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          padding: { xs: "15px", sm: "20px" }, // Responsive padding
          borderRadius: "5px",
          ml: { xs: "0", sm: "86px" }, // Responsive margin-left
          mt: "20px",
          mb: "20px",
        }}
      >
        <Typography sx={{ mb:2 ,fontSize: { xs: "18px", sm: "20px" } }}>
          <strong>No Upfront Fees – Pay After You Get a Job</strong>
        </Typography>
        <Typography
          sx={{ mb: 2, fontSize: { xs: "16px", sm: "18px" } }}
          color="textSecondary"
        >
          We offer a Pay After Placement model to ensure that you can focus on
          your education without financial stress. Once you secure a job, you'll
          pay a portion of your fees based on your salary range.
        </Typography>
      </Box>

      <Typography
        sx={{
          marginLeft: { xs: "0", sm: "86px" }, // Responsive margin-left
          fontSize: { xs: "22px", sm: "26px" }, // Responsive font size
          fontWeight: "bold",
          mt: 3,
          mb: 2,
        }}
        variant="h6"
        gutterBottom
      >
        Key Features
      </Typography>

      <Grid container spacing={2} sx={{ ml: { xs: "0", sm: "66px" } }}>
        {[
          { icon: "%", text: "No loans or interest rates." },
          {
            icon: "/*",
            text: "Flexible payment plans aligned with your earnings.",
          },
          { icon: "$", text: "Pay only after you secure a job." },
        ].map(({ icon, text }, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              elevation={0}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginRight: { xs: "20px", sm: "20px" },
              }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "blue",
                    mb: 2,
                    fontSize: { xs: "24px", sm: "36px" },
                    fontWeight: "bold",
                  }}
                >
                  {icon}
                </Typography>
                <Typography sx={{ fontSize: { xs: "16px", sm: "20px" } }}>
                  {text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Redirect
        title={<Typography sx={{ fontWeight: 600, fontSize: { xs: "26px", sm: "32px" } }}>Know more about Pay After Placement(PAP)</Typography>}
        content= {
          <>
            <Typography sx={{ fontWeight: 600}} color="textSecondary" >
              No loan, No collaterals, No interest rate.
            </Typography>
          </>
          }
        buttonText="Learn more about PAP"
        textColor="black"
        backgroundColor="#F6F6F6"
        buttonLink="/pay-after-placement"
        bottomMargin={20}
      />
    </Box>
    
  );
};

export default PayAfterPlacement;
